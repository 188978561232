import { FC } from 'react'

import { cva } from 'class-variance-authority'

type IconArrowRightProps = {
  width?: string
  height?: string
  color?: 'gray' | 'brand' | 'cta' | 'white'
  strokeWidth?: number
  className?: string
}

const strokeVariants = cva(null, {
  variants: {
    color: {
      gray: 'stroke-grayText',
      brand: 'stroke-brand',
      cta: 'stroke-cta',
      white: 'stroke-white',
    },
  },
})

export const IconArrowRight: FC<IconArrowRightProps> = ({
  width = '16',
  height = '16',
  color = 'gray',
  strokeWidth = 1.4,
  className,
}) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={strokeVariants({ color })}
        d="M6 12L10 8L6 4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}
